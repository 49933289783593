// FIXME: Get info from ticketDetails instead
// TODO: Implement on server
const transporterIdToTransporterName = (id) => {
  if (id === 1) {
    return "cooploja";
  }
  if (id === 5) {
    return "coopnambija";
  }
  if (id === 7) {
    return "ruorenses";
  }
  if (id === 8) {
    return "cooptrasandina";
  }
  if (id === 6) {
    return "coopbanos";
  }
  if (id === 4) {
    return "ucariamanga";
  }
  if (id === 3) {
    return "uyantzaza";
  }

  return "unknown";
};

// FIXME: Standardize this by having it generated on server by booking engine
const ticketToQrCodeText = (ticket) => {
  const o = {
    tipoqr: "boleto",
    empresa: transporterIdToTransporterName(
      ticket.ticketDetails.transporter.id
    ),
    trn_codigo:
      ticket.bookingOutput &&
      ticket.bookingOutput.apiData &&
      ticket.bookingOutput.apiData.trn_codigo
        ? ticket.bookingOutput.apiData.trn_codigo
        : ticket.id,
  };

  return JSON.stringify(o);
};

export default ticketToQrCodeText;
