import React, { Component } from "react";
import fetch from "isomorphic-fetch";
import moment from "moment";
import check from "check-types";
import { TicketBoardingPassPage } from "@seafront/busea.web-app.theme";
import {
  translationManager,
  translatorForNamespace
} from "../../app-lib/TranslationManager";
import ticketToQrCodeText from "../../app-lib/qrcode";
import "semantic-ui-css/semantic.min.css";
import "./index.css";

import { functionsEndpoint } from "../../config/firebase.json";
import { appleStoreLink, googlePlayLink } from "../../config/busea.json";

import LoadingPage from "../../LoadingPage";
import PageNotFound from "../../PageNotFound";

export default class Ticket extends Component {
  state = {
    ticket: null,
    passengerIndex: 0,
    translations: {},
    lang: "es",
    loaded: false,
    error: false
  };

  async componentDidMount() {
    const { id } = this.props;

    if (!check.nonEmptyString(id)) {
      this.setState({ loaded: true, error: true });
      return;
    }

    // Fetch info about ticket
    const [userSha1, orderId, tripType, passengerIndex, lang] = id.split("-");

    if (!userSha1 || !orderId || !tripType || !passengerIndex || !lang) {
      this.setState({ loaded: true, error: true });
      return;
    }

    // Redirect to download link if URL contains "?download"
    if (window.location.search.indexOf("?dl") === 0) {
      window.location.href =
        `https://${functionsEndpoint}/api/tickets/` +
        `${userSha1}/${orderId}/${tripType}/${passengerIndex}`;
    }

    let response = null;
    response = await fetch(
      `https://${functionsEndpoint}/api/tickets/` +
        `${userSha1}/${orderId}/${tripType}/${passengerIndex}`
    );
    if (response.status >= 400) {
      this.setState({ loaded: true, error: true });
      return;
    }

    const json = await response.json();

    const { ticket } = json;

    if (!ticket) {
      this.setState({ loaded: true, error: true });
      return;
    }

    this.setState({
      ticket,
      passengerIndex: parseInt(passengerIndex, 10),
      loaded: true,
      error: false,
      lang
    });
  }

  render() {
    const { loaded, error, ticket, passengerIndex, lang } = this.state;

    // Set language
    translationManager.setLanguage(lang);
    moment.locale(lang);

    if (!loaded) {
      return <LoadingPage />;
    }

    if (error || !ticket) {
      return (
        <>
          <PageNotFound />
          <div id="ticket-loaded" />
        </>
      );
    }

    // Generate QR Code
    const qrCodeData = ticketToQrCodeText(ticket);

    return (
      <>
        <TicketBoardingPassPage
          {...{
            ticket,
            qrCodeData,
            passengerIndex,
            translatorForNamespace,

            inPopup: false,
            showCarouselControls: false,
            showPlatformLogo: true,
            showAppStoreButtons: false,
            appleStoreLink,
            googlePlayLink
          }}
        />
        {/* Following div used to tell PDF generator that page is loaded */}
        <div id="ticket-loaded" />
      </>
    );
  }
}
