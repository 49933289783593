import React, { Component } from "react";
import check from "check-types";
import firebase from "firebase";
import queryString from "query-string";
import firebaseConfig from "./config/firebase";
import {
  translationManager,
  translatorForNamespace
} from "./app-lib/TranslationManager";
import LoadingPage from "./LoadingPage";
import PageNotFound from "./PageNotFound";
import Ticket from "./pages/ticket";
import S from "./pages/s";

// Init Firebase
firebase.initializeApp(firebaseConfig);

class App extends Component {
  state = { loaded: false };

  async componentDidMount() {
    // Init translation manager
    await translationManager.init("busea", firebase, {}, "/translations");
    this.translationManager = translatorForNamespace(
      "TicketBoardingPassPageUI"
    );

    this.setState({ loaded: true });
  }

  render() {
    const { loaded } = this.state;

    let { page, id } = queryString.parse(window.location.search);

    // console.log("Params from query string: ", { page, id });

    if (!page || !id) {
      const currentPath = window.location.pathname;

      if (currentPath.indexOf("/ticket/") === 0) {
        page = "ticket";
        [, , id] = currentPath.split("/");
      } else if (currentPath.indexOf("/s/") === 0) {
        page = "s";
        [, , id] = currentPath.split("/");
      } else {
        window.location.href = "https://busea.ec";
        return <PageNotFound />;
      }
    }

    // console.log("Params from query string and path: ", { page, id });

    if (!check.nonEmptyString(id)) {
      return <PageNotFound />;
    }

    if (!loaded) {
      return <LoadingPage />;
    }

    if (page === "ticket") {
      return <Ticket id={id} translationManager={this.translationManager} />;
    }
    if (page === "s") {
      return <S id={id} translationManager={this.translationManager} />;
    }

    return <PageNotFound />;
  }
}

export default App;
