import React from "react";
import { LoadingPlaceHolder } from "@seafront/busea.web-app.theme";

export default function LoadingPage() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <LoadingPlaceHolder />
    </div>
  );
}
